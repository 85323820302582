import React from 'react'
import { Link } from 'react-router-dom';
import { useLanguage } from '../../hooks/LanguageContext';
import "./kunafa.scss";

const Kunafa = () => {
    const { language } = useLanguage();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

 const content = {
   en: {
     description:
       "Chocodate Funafa: A Decadent Fusion of Tradition and Luxury. Experience the indulgence of premium chocolate, stuffed with a plump medjool date, all wrapped in a sprinkle of Funafa magic. Each bite is a celebration of sweet, rich flavor and satisfying texture, merging old-world charm with gourmet elegance. Perfect for those who crave an extraordinary treat with every indulgence.",
     orderNow: "Order Now",
   },
   ar: {
     description:
       "شوكوديت فنفا: مزيج فاخر من التقاليد والفخامة. استمتع بتجربة الشوكولاتة الفاخرة المحشوة بتمر مدجول ممتلئ، ومكمل بألوزة مقرمشة، جميعها مع لمسة سحرية من فنفا. كل قضمة هي احتفال بالنكهات الحلوة والغنية والملمس المرضي، ممزوجة بسحر العالم القديم وأناقة المأكولات الراقية. مثالية لأولئك الذين يتوقون إلى تجربة استثنائية مع كل لحظة تذوق.",
     orderNow: "اطلب الآن",
   },
   ru: {
     description:
       "Chocodate Funafa: Потрясающее слияние традиций и роскоши. Наслаждайтесь премиум-шоколадом, наполненным сочным фиником меджул, дополненным хрустящим миндалем, все с добавлением магии Funafa. Каждый кусочек — это праздник сладкого, насыщенного вкуса и удовлетворяющей текстуры, сочетающий старинное очарование и кулинарную элегантность. Идеально для тех, кто жаждет необычного лакомства с каждым укусом.",
     orderNow: "Заказать сейчас",
   },
   hi: {
     description:
       "Chocodate Funafa: परंपरा और लग्जरी का शानदार मिश्रण। प्रीमियम चॉकलेट का आनंद लें, जिसमें भरा हुआ एक मोटा मेजूल खजूर है, जो कुरकुरी बादाम के साथ सजा हुआ है, और सब कुछ Funafa के जादू से सजीव है। हर काटने में मीठे, समृद्ध स्वाद और संतोषजनक बनावट का उत्सव है, जो पुराने जमाने के आकर्षण और उच्च-स्तरीय रसोई के साथ मिश्रित है। यह उन लोगों के लिए आदर्श है जो हर लाजवाब लजीजता के साथ एक असाधारण अनुभव चाहते हैं।",
     orderNow: "अब ऑर्डर करें",
   },
   zh: {
     description:
       "Chocodate Funafa：传统与奢华的完美融合。体验极致的巧克力，里面塞满了饱满的美洲枣，搭配脆脆的杏仁，并撒上一点Funafa魔法。每一口都在庆祝甜美、丰富的味道和令人满意的质地，融合了古老的魅力与美食优雅。非常适合那些渴望每一口都是非凡享受的人。",
     orderNow: "立即订购",
   },
   ja: {
     description:
       "Chocodate Funafa: 伝統と贅沢が融合した贅沢な体験。プレミアムチョコレートにジューシーなメジュールデーツを詰め、カリカリのアーモンドで飾り、すべてFunafaの魔法がかかっています。一口ごとに甘く豊かな味わいと満足感のある食感が広がり、古き良き魅力とグルメな優雅さが融合しています。特別なご褒美を求めるすべての方にぴったりな逸品です。",
     orderNow: "今すぐ注文",
   },
   ko: {
     description:
       "Chocodate Funafa: 전통과 고급스러움의 완벽한 융합. 프리미엄 초콜릿 안에 풍성한 메줄 대추를 채우고, 바삭한 아몬드와 Funafa의 마법으로 모든 것이 완성됩니다. 한 입마다 달콤하고 풍부한 맛과 만족스러운 식감을 기념하며, 고풍스러운 매력과 고급스러운 우아함을 결합합니다. 매 순간 특별한 맛을 원하는 이들에게 완벽한 선택입니다.",
     orderNow: "지금 주문",
   },
 };



  return (
    <div className="max-width">
      <div
        className="brandInfo"
        style={{
          //   backgroundImage: `url(${bkg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="wegan">
          <h1 style={{ fontSize: "60px", fontWeight: "800" }}>kunafa</h1>
          {/* <img src={require("../../img/vegan.png")} alt="" /> */}
        </div>
        <div className="des">
          <p>{content[language].description}</p>
        </div>
        <div className="kf_brandInfo_wrp">
          <Link to={"/product/105"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/small.jpg")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #743f2b" }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>
          </Link>
          {/* <Link to={"/product/94"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/02.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #482565" }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>{" "}
          </Link> */}

          {/* <Link to={"/product/92"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/03.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #0872ba" }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>{" "}
          </Link> */}
          {/* <div className="item">
            <div className="item_img">
              <img src={require("../../img/04.png")} alt="" />
            </div>
            <div
              className="item_price"
              style={{ borderTop: "2px solid #0e6e68" }}
            >
              <button>pre order</button>
            </div>
          </div>{" "} */}
          <Link to={"/product/106"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/kf.jpeg")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #221f1f" }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>
          </Link>
        </div>
        {/* <div className="symbol">
          <img src={require("../../img/symbol2.png")} alt="" />
        </div> */}
      </div>
    </div>
  );
}


export default Kunafa
