import React from 'react'
import "./brandInfo.scss";
import Kunafa from '../../components/kunafa/Kunafa';

const KunafaPage = () => {
  return (
    <div className="pro_wrp">
      <Kunafa />
    </div>
  );
}

export default KunafaPage
