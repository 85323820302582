import React from "react";
import FeaturedProducts from "../FeaturedProducts/FeaturedProducts";
import "./Home.scss";

import sample from "../../img/hero.mp4";
import { useLanguage } from "../../hooks/LanguageContext";
import Newsletter from "../../components/Newsletter/Newsletter";
import SlideShow from "../../components/slideshow/SlideShow";

const Home = () => {
  const { language } = useLanguage();

  const content = {
    en: {
      heading: "Simply Delicious",
      ingredientsHeading: "Our Ingredients",
      introText:
        "At Chocodate, we’ve created a luxurious experience with a wholesome approach to health and wellness that will give you a natural boost of goodness.",
      ingredient1: {
        title: "Arabian Dates",
        text: "Arabian Dates are naturally chewy with a sweet flavor and an excellent nutrition profile. High in antioxidants, including dates in your diet is a great way to increase fiber intake.",
      },
      ingredient2: {
        title: "Belgian Chocolate",
        text: "Belgian chocolate has been the food of champions and a favorite of Kings. It tends to have a higher cocoa content, most notably in dark chocolate.",
      },
      sustainabilityHeading: "Sustainability",
      sustainabilityText:
        "Our ambition is to operate in a very sustainable and ethical environment. Here’s how:",
      sustainabilityPoints: [
        "Our factory runs on 80% solar energy with over 3000 solar panels.",
        "We aim to reduce greenhouse emissions by increasing the use of renewable electricity.",
        "We reduce food wastage by encouraging mindful snacking habits with clear and concise labeling.",
        "Using the latest technology, we aim to increase recyclable and reusable packaging.",
        "Our production facilities are certified to ensure food safety at the highest standards.",
      ],
      testimonialsHeading: "What customers say about us",
    },
    ar: {
      heading: "لذيذ ببساطة",
      ingredientsHeading: "مكوناتنا",
      introText:
        "في شوكوديت، قمنا بإنشاء تجربة فاخرة مع نهج شامل للصحة والعافية يمنحك دفعة طبيعية من الخير.",
      ingredient1: {
        title: "تمور عربية",
        text: "التمور العربية بطبيعتها طرية وحلوة الطعم، ولها قيمة غذائية ممتازة. تعتبر مصدرًا غنيًا بمضادات الأكسدة، وإضافتها إلى نظامك الغذائي هي طريقة رائعة لزيادة تناول الألياف.",
      },
      ingredient2: {
        title: "الشوكولاتة البلجيكية",
        text: "الشوكولاتة البلجيكية كانت غذاء الأبطال والمفضلة لدى الملوك. تحتوي عادةً على نسبة أعلى من الكاكاو، وخاصة في الشوكولاتة الداكنة.",
      },
      sustainabilityHeading: "الاستدامة",
      sustainabilityText:
        "طموحنا هو العمل في بيئة مستدامة وأخلاقية للغاية. إليك كيف:",
      sustainabilityPoints: [
        "يعمل مصنعنا بالطاقة الشمسية بنسبة 80٪ مع أكثر من 3000 لوحة شمسية.",
        "نسعى لتقليل انبعاثات غازات الاحتباس الحراري بزيادة استخدام الكهرباء المتجددة.",
        "نقلل من هدر الطعام من خلال تشجيع عادات الوجبات الخفيفة الواعية من خلال وضع علامات واضحة وموجزة.",
        "نهدف إلى زيادة استخدام التعبئة والتغليف القابلة لإعادة التدوير باستخدام أحدث التقنيات.",
        "منشآت الإنتاج لدينا معتمدة لضمان سلامة الغذاء وفقًا لأعلى المعايير.",
      ],
      testimonialsHeading: "ماذا يقول عملاؤنا عنا",
    },
    ru: {
      heading: "Просто Вкусно",
      ingredientsHeading: "Наши Ингредиенты",
      introText:
        "В Chocodate мы создали роскошный опыт с заботой о здоровье и благополучии, который подарит вам естественный заряд бодрости.",
      ingredient1: {
        title: "Арабские Финики",
        text: "Арабские финики естественно жевательные, с приятным сладким вкусом и отличным питательным профилем. Они богаты антиоксидантами, и включение фиников в рацион — отличный способ увеличить потребление клетчатки.",
      },
      ingredient2: {
        title: "Бельгийский Шоколад",
        text: "Бельгийский шоколад был пищей чемпионов и любимцем королей. Он, как правило, имеет более высокое содержание какао, особенно в темном шоколаде.",
      },
      sustainabilityHeading: "Устойчивость",
      sustainabilityText:
        "Наша цель — работать в устойчивой и этичной среде. Вот как мы это делаем:",
      sustainabilityPoints: [
        "Наш завод работает на 80% солнечной энергии, используя более 3000 солнечных панелей.",
        "Мы стремимся сократить выбросы парниковых газов, увеличивая использование возобновляемой электроэнергии.",
        "Мы сокращаем пищевые отходы, поощряя осознанные привычки перекусов с помощью четкой и понятной маркировки.",
        "Используя новейшие технологии, мы стремимся увеличить долю перерабатываемой и повторно используемой упаковки.",
        "Наши производственные объекты сертифицированы для обеспечения безопасности пищевых продуктов на самом высоком уровне.",
      ],
      testimonialsHeading: "Отзывы наших клиентов",
    },
    ja: {
      heading: "シンプルに美味しい",
      ingredientsHeading: "私たちの食材",
      introText:
        "Chocodateでは、健康とウェルネスへの包括的なアプローチで贅沢な体験を提供し、自然な活力をもたらす製品を作り上げました。",
      ingredient1: {
        title: "アラビアのデーツ",
        text: "アラビアのデーツは、自然に噛み応えがあり、甘い味と優れた栄養価を持っています。抗酸化物質が豊富で、デーツを食事に取り入れることは食物繊維の摂取を増やす素晴らしい方法です。",
      },
      ingredient2: {
        title: "ベルギーチョコレート",
        text: "ベルギーチョコレートは、チャンピオンの食べ物であり、王族の好物でした。特にダークチョコレートでは、カカオの含有量が高いことが特徴です。",
      },
      sustainabilityHeading: "持続可能性",
      sustainabilityText:
        "私たちの目標は、非常に持続可能で倫理的な環境で運営することです。以下の方法で実現しています：",
      sustainabilityPoints: [
        "当社の工場は、3000枚以上の太陽光パネルで80%の太陽エネルギーを使用しています。",
        "再生可能エネルギーの使用を増やすことで、温室効果ガスの排出を削減することを目指しています。",
        "食品の無駄を減らすために、明確で簡潔なラベルを通じて意識的なスナック習慣を促進しています。",
        "最新技術を使用して、リサイクル可能で再利用可能なパッケージの増加を目指しています。",
        "当社の生産施設は、最高の基準で食品安全を保証する認証を受けています。",
      ],
      testimonialsHeading: "お客様の声",
    },
    ko: {
      heading: "간단히 맛있는",
      ingredientsHeading: "우리의 재료",
      introText:
        "Chocodate에서는 건강과 웰빙에 대한 포괄적인 접근 방식을 통해 고급스러운 경험을 제공하며, 자연적인 활력을 주는 제품을 만들었습니다.",
      ingredient1: {
        title: "아라비아 대추",
        text: "아라비아 대추는 자연스럽게 쫄깃하고 달콤한 맛과 뛰어난 영양가를 가지고 있습니다. 항산화제가 풍부하여 대추를 식단에 포함시키는 것은 섬유질 섭취를 늘리는 좋은 방법입니다.",
      },
      ingredient2: {
        title: "벨기에 초콜릿",
        text: "벨기에 초콜릿은 챔피언의 음식이자 왕족의 좋아하는 음식이었습니다. 특히 다크 초콜릿에서 카카오 함량이 높은 것이 특징입니다.",
      },
      sustainabilityHeading: "지속 가능성",
      sustainabilityText:
        "우리의 목표는 매우 지속 가능하고 윤리적인 환경에서 운영하는 것입니다. 아래 방법으로 실현하고 있습니다:",
      sustainabilityPoints: [
        "우리 공장은 3000개 이상의 태양광 패널로 80%의 태양광 에너지를 사용합니다.",
        "재생 가능한 전기 사용을 증가시켜 온실가스 배출을 줄이는 것을 목표로 하고 있습니다.",
        "명확하고 간결한 라벨을 통해 신중한 간식 습관을 장려하여 음식 낭비를 줄이고 있습니다.",
        "최신 기술을 사용하여 재활용 가능하고 재사용 가능한 포장을 증가시키는 것을 목표로 하고 있습니다.",
        "우리의 생산 시설은 최고 수준의 식품 안전을 보장하는 인증을 받았습니다.",
      ],
      testimonialsHeading: "고객의 평가",
    },
    hi: {
      heading: "सिर्फ़ स्वादिष्ट",
      ingredientsHeading: "हमारे घटक",
      introText:
        "Chocodate में, हमने स्वास्थ्य और कल्याण के प्रति एक समग्र दृष्टिकोण के साथ एक शानदार अनुभव तैयार किया है, जो आपको अच्छाई का प्राकृतिक बढ़ावा देगा।",
      ingredient1: {
        title: "अरबी खजूर",
        text: "अरबी खजूर स्वाभाविक रूप से चबाने में सख्त होते हैं, एक मीठा स्वाद और एक बेहतरीन पोषण प्रोफ़ाइल रखते हैं। एंटीऑक्सीडेंट में उच्च, अपने आहार में खजूर को शामिल करना फाइबर सेवन बढ़ाने का एक शानदार तरीका है।",
      },
      ingredient2: {
        title: "बेल्जियम चॉकलेट",
        text: "बेल्जियम चॉकलेट चैंपियनों का भोजन और राजाओं का पसंदीदा था। इसमें उच्च कोको सामग्री होती है, विशेष रूप से डार्क चॉकलेट में।",
      },
      sustainabilityHeading: "स्थिरता",
      sustainabilityText:
        "हमारा उद्देश्य एक अत्यधिक स्थिर और नैतिक वातावरण में कार्य करना है। हम इसे इस प्रकार लागू करते हैं:",
      sustainabilityPoints: [
        "हमारा कारखाना 3000 से अधिक सौर पैनलों के साथ 80% सौर ऊर्जा पर चलता है।",
        "हम नवीकरणीय बिजली के उपयोग को बढ़ाकर ग्रीनहाउस गैसों के उत्सर्जन को कम करने का लक्ष्य रखते हैं।",
        "हम स्पष्ट और संक्षिप्त लेबलिंग के माध्यम से सचेत स्नैकिंग आदतों को प्रोत्साहित करके खाद्य अपव्यय को कम करते हैं।",
        "नवीनतम तकनीक का उपयोग करके, हम पुनर्नवीनीकरण और पुन: उपयोग योग्य पैकेजिंग को बढ़ाने का लक्ष्य रखते हैं।",
        "हमारी उत्पादन सुविधाएं खाद्य सुरक्षा को उच्चतम मानकों पर सुनिश्चित करने के लिए प्रमाणित हैं।",
      ],
      testimonialsHeading: "हमारे ग्राहकों का क्या कहना है",
    },
    zh: {
      heading: "简直美味",
      ingredientsHeading: "我们的食材",
      introText:
        "在Chocodate，我们通过健康和福利的整体方法创造了奢华的体验，让您获得自然的健康能量。",
      ingredient1: {
        title: "阿拉伯枣",
        text: "阿拉伯枣天然富有嚼劲，具有甜美的风味和出色的营养价值。富含抗氧化剂，增加膳食中枣的摄入是增加纤维摄入的绝佳方式。",
      },
      ingredient2: {
        title: "比利时巧克力",
        text: "比利时巧克力是冠军的食物，也是国王们的最爱。它通常含有较高的可可含量，尤其是在黑巧克力中。",
      },
      sustainabilityHeading: "可持续性",
      sustainabilityText:
        "我们的目标是营造一个非常可持续和道德的环境。我们通过以下方式实现这一目标：",
      sustainabilityPoints: [
        "我们的工厂使用超过3000块太阳能板，80%能源来自太阳能。",
        "我们通过增加可再生电力的使用来减少温室气体排放。",
        "我们通过清晰简洁的标签，鼓励有意识的零食习惯，减少食物浪费。",
        "通过使用最新技术，我们致力于增加可回收和可重复使用的包装。",
        "我们的生产设施获得认证，确保最高标准的食品安全。",
      ],
      testimonialsHeading: "顾客对我们的评价",
    },
  };


  const localizedContent = content[language];

  return (
    <div
      className="max-width"
      style={{ direction: language === "ar" ? "rtl" : "ltr" }}
    >
      <div className="home">
        <div className="hero_wrappe ">
          <div className="video">
            <video className="videoTag" autoPlay loop muted>
              <source src={sample} type="video/mp4" />
            </video>
          </div>
          <div className="heading">
            <p>{content[language].introText}</p>
          </div>
        </div>
        <div className="heading-two">
          <h1 style={{ fontSize: "50px" }}>{localizedContent.heading}</h1>
        </div>
        <div>
          <div>
            <h4>New Releases</h4>
          </div>
          <FeaturedProducts type="tranding" />
        </div>

        {/* <FeaturedProducts type="featured" /> */}

        <div className="text_ing">
          <h1>{localizedContent.ingredientsHeading}</h1>
          <div className="item_one">
            <div></div>
            <div className="item_one_sec">
              <img src={require("../../img/almond.webp")} alt="Almond" />
              <div className="text_con">
                <p>{localizedContent.ingredient1.text}</p>
              </div>
            </div>
            <div></div>
          </div>

          <div className="main_img">
            <img src={require("../../img/dates.jpg")} alt="Dates" />
          </div>

          <div className="item_two">
            <div className="item_two_in">
              <div className="item_two_in_img">
                <img src={require("../../img/date.webp")} alt="Date" />
              </div>
              <div className="item_two_in_text">
                <p>{localizedContent.ingredient1.text}</p>
              </div>
            </div>
            <div className="item_two_in">
              <div className="item_two_in_img">
                <img src={require("../../img/choco.webp")} alt="Chocolate" />
              </div>
              <div className="item_two_in_text">
                <p>{localizedContent.ingredient2.text}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="product_info_section_one">
          <div className="img_wrap">
            <img src={require("../../img/sus.png")} alt="Sustainability" />
          </div>
          <div className="text">
            <h1>{localizedContent.sustainabilityHeading}</h1>
            <strong>{localizedContent.sustainabilityText}</strong>
            <ul>
              {localizedContent.sustainabilityPoints.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
        </div>

        <div className="testimonial">
          <h2>{localizedContent.testimonialsHeading}</h2>
          <SlideShow />
        </div>
        <div>
          <div>
            <h4>Bestselling</h4>
          </div>
          <FeaturedProducts type="featured" />
        </div>

        <Newsletter />
      </div>
    </div>
  );
};

export default Home;
