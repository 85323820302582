import React from 'react'
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Home from './page/Home/Home'
import Products from './page/Products/Products';
import Product from './page/Product/Product';
import Contact from './page/Contact/Contact';
import Promotion from './page/promotion/Promotion';
import About from './page/About/About';
import PrivacyPolicy from './page/Policy/PrivacyPolicy';
import Succsess from './components/payment/Succsess';
import GourmetPage from './page/brandInfo/GourmetPage';
import VeganPage from './page/brandInfo/VeganPage';
import BitesPage from './page/brandInfo/BitesPage';
import Failed from './components/payment/Failed';
import Payment from './page/checkOut/Payment';
import Navbar from './components/Navbar/Navbar';
import Whatapp from './components/whatapp/Whatapp';
import Footer from './components/Footer/Footer';
import Error from './page/Error/Error';
import NewFooter from './components/new_footer/NewFooter';
import Conditions from './page/t&c/Conditions';
import axios from "axios";
import KunafaPage from './page/brandInfo/KunafaPage';

axios.defaults.baseURL = "https://api-master.notionsgroup.com/api/v1";
// axios.defaults.baseURL = "http://localhost:4343/api/v1";
axios.defaults.withCredentials = true;

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Whatapp />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products/:id" element={<Products />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/promotion" element={<Promotion />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms-&-conditions" element={<Conditions />} />
        <Route path="/succsess" element={<Succsess />} />
        <Route path="/gourmet" element={<GourmetPage />} />
        <Route path="/vegan" element={<VeganPage />} />
        <Route path="/kunafa" element={<KunafaPage />} />
        <Route path="/bites" element={<BitesPage />} />
        <Route path="/failed" element={<Failed />} />
        <Route path="*" element={<Error />} />
        <Route path="/mail" element={<Error />} />
        <Route path="/check_out" element={<Payment />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App
